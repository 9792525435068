import './App.css'
import React, { useContext, useId, useState } from 'react';
import {AuthContext} from '../contexts/auth';

import { toast } from 'react-toastify';
import lg from '../Asserts/lg.png' 
import { Link } from 'react-router-dom';



function Singup() {
  const [email,setEmail] = useState("")
  const [pw,setPw] = useState("")
  const {singup} = useContext(AuthContext)
  const [check,setCheck] = useState(false)



  useState(()=>{
   
   
  })

  async function handlelogin(e){
    e.preventDefault();
    if(email !== "" && pw !== "" && check !== false){
    await singup(email,pw)
 
    }
    
 

    else if(email == "" && pw == ""){
      toast.error('¡Rellene los campos con nombre de usuario y contraseña!')
    }
    else if(email == ""){
      toast.error('¡Rellene el campo de inicio de sesión!')
    }
    else if(pw == ""){
      toast.error('¡Rellene el campo de contraseña!')
    }
    else if(check == false){
      toast.error('Rellene el campo Condiciones de uso!')
    }
  }


  return (
    <div className="container-center">
  
      <div className='login'>
        <div className='login-area'>
          <img src={lg}  />
         
        </div>
        
        <h1>Ingresa tu información</h1>
     
        <form onSubmit={handlelogin}>
        

          <input type='text' 
          placeholder='Correo electrónico' 
          value={email} 
          onChange={(e)=>setEmail(e.target.value)}
          />

          <input 
          type='password'
          placeholder='Contraseña'
          value={pw}
          onChange={(e)=>setPw(e.target.value)}
          
          />
          <div className='check' >
          <input onChange={(e)=>setCheck(e.target.checked)}  type='checkbox'/>
        <Link to={'/termos'}><b>Leer</b> e <b>Estoy</b> Según <b>de acuerdo con los Términos de uso</b></Link>
          </div>
        <button type='submit'>Registro</button>
        </form>

      </div>
     
    </div>
  );
}

export default Singup;
